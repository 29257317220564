import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnumTextPipe } from './enum-text.pipe';
import { TimeSincePipe } from './time-since.pipe';


@NgModule({
  declarations: [
    EnumTextPipe,
    TimeSincePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    EnumTextPipe
  ]
})
export class PipesModule { }
